import * as React from "react";
import { Timeline, Tween, PlayState } from "react-gsap";
import useOnScreen from "../../../../hooks/useOnScreen";

const CupOfTeaIllustration = React.forwardRef((props, targets: any) => (
  <svg width="293" height="263" viewBox="0 0 293 263" fill="none">
    <g clipPath="url(#clip0)">
      <g id="water" ref={(g) => targets?.set("water", g)}>
        <path
          id="water 2"
          d="M79.916 162.234V171.164C79.916 207.771 109.49 237.553 145.841 237.553C182.192 237.553 211.766 207.771 211.766 171.164V162.234H79.916Z"
          fill="#2EC0BF"
        />
        <path
          id="water 1"
          opacity="0.3"
          d="M79.7233 162.429V171.358C79.7233 207.965 109.297 237.747 145.648 237.747C182 237.747 211.573 207.965 211.573 171.358V162.429H79.7233Z"
          fill="#2EC0BF"
        />
      </g>
      <g id="background overlay">
        <path
          d="M0 264.5V0H144.5V162H80C76.5 199 100 234 144.5 237.772V264.5H0Z"
          fill="#DFE5FE"
        />
        <path
          d="M144.5 162V-0.5H293V264.5H144.5V237.762C188.5 237 215.5 201.5 211.5 162H144.5Z"
          fill="#DFE5FE"
        />
      </g>
      <path
        id="Vector"
        d="M109.181 186.977L75.4938 242.965L75.5632 248.653C73.0996 248.672 70.6593 248.631 68.242 248.528L69.919 175.411L69.8728 174.848L69.9345 174.739L70.0925 167.829L33.1283 113.782L70.0501 162.657L70.1812 164.117L71.4457 108.874L39.5164 52.8434L71.5383 99.1722L71.3185 24.4591L71.2491 -3.05176e-05H71.573L72.1474 24.4591L73.3348 74.9188L102.43 38.2766L73.4273 82.6875L74.0635 132.103L100.842 83.8755L74.1637 139.367L74.5145 166.843L113.457 99.6653L74.634 176.428L75.4244 237.526L109.181 186.977Z"
        fill="#146E81"
      />
      <path
        id="Vector_2"
        d="M263.041 170.325L252.381 191.403L225.676 244.204H219.546L219.677 238.489L187.474 191.403L182.712 184.442L187.971 191.403L219.634 233.317L219.765 234.777L220.76 191.403L221.03 179.534L189.101 123.503L221.122 169.832L220.787 55.3826V55.0021L220.799 55.3787L222.919 145.579L252.015 108.937L223.012 153.347L223.501 191.403L223.648 202.763L229.955 191.403L250.426 154.535L232.7 191.403L223.748 210.027L224.099 237.502L250.823 191.403L263.041 170.325Z"
        fill="#146E81"
      />
      <path
        id="Vector_3"
        d="M246.656 243.463C246.656 248.344 201.779 263 146.419 263C91.06 263 46.1824 248.344 46.1824 243.463C46.1824 238.581 91.06 245.323 146.419 245.323C201.779 245.323 246.656 238.581 246.656 243.463Z"
        fill="#3F3D56"
      />
      <path
        id="Vector_4"
        opacity="0.2"
        d="M246.656 243.463C246.656 248.344 201.779 263 146.419 263C91.06 263 46.1824 248.344 46.1824 243.463C46.1824 238.581 91.06 245.323 146.419 245.323C201.779 245.323 246.656 238.581 246.656 243.463Z"
        fill="black"
      />
      <path
        id="Vector_5"
        d="M146.419 252.301C201.779 252.301 246.656 248.344 246.656 243.463C246.656 238.581 201.779 234.624 146.419 234.624C91.06 234.624 46.1824 238.581 46.1824 243.463C46.1824 248.344 91.06 252.301 146.419 252.301Z"
        fill="#3F3D56"
      />
      <path
        id="Vector_6"
        opacity="0.2"
        d="M145.648 248.23C168.431 248.23 186.9 246.492 186.9 244.347C186.9 242.203 168.431 240.465 145.648 240.465C122.866 240.465 104.397 242.203 104.397 244.347C104.397 246.492 122.866 248.23 145.648 248.23Z"
        fill="black"
      />
      <path
        id="cup"
        d="M215 159H76.5C73 206.5 102.5 239.5 145 241C168.991 241.847 194.828 228.29 207.5 203.037M215 159C215 159 250.5 157.5 247 183C243.5 208.5 207.5 203.037 207.5 203.037M215 159C216 178.5 213.57 190.942 207.5 203.037"
        stroke="#3F3D56"
        strokeWidth="7"
      />
      <g id="tea_bag" ref={(g) => targets?.set("tea_bag", g)}>
        <path
          id="Vector_7"
          d="M128.604 225.389L113.511 209.725C111.988 208.144 111.151 206.019 111.184 203.816C111.217 201.614 112.117 199.515 113.687 197.981L136.514 175.676C137.058 175.145 137.7 174.725 138.404 174.442L143.362 172.447C144.432 172.016 145.604 171.916 146.73 172.158C147.857 172.4 148.886 172.973 149.688 173.806L165.449 190.163C166.226 190.97 166.756 191.986 166.976 193.088C167.195 194.19 167.095 195.333 166.686 196.379L165.018 200.651C164.715 201.427 164.251 202.128 163.657 202.709L140.265 225.566C138.695 227.1 136.585 227.943 134.398 227.91C132.211 227.876 130.127 226.97 128.604 225.389Z"
          fill="#146E81"
        />
        <path
          id="Vector_8"
          d="M159.202 187.487L151.87 179.877L150.737 180.984L158.069 188.594L159.202 187.487Z"
          fill="#3F3D56"
        />
        <path
          id="Vector_9"
          d="M155.685 184.637C157.02 181.448 160.997 180.761 163.954 180.129C167.099 179.457 170.68 178.288 171.987 174.995C172.268 174.321 172.377 173.586 172.304 172.858C172.23 172.131 171.976 171.433 171.564 170.831C170.796 169.72 169.501 168.817 168.102 168.943C165.174 169.207 164.652 172.69 165.771 174.888C167.281 177.855 171.159 178.113 174.061 178.186C176.864 178.257 179.872 178.149 182.434 176.873C183.641 176.282 184.649 175.348 185.335 174.186C186.022 173.025 186.355 171.686 186.295 170.336C186.205 167.488 184.957 164.83 184.212 162.123C183.495 159.521 183.27 156.636 185.177 154.472C187.024 152.376 189.937 151.548 192.592 151.125C193.274 151.016 193.96 150.942 194.648 150.886C195.385 150.827 195.391 149.661 194.648 149.722C189.725 150.12 183.273 151.828 182.566 157.683C182.242 160.359 183.247 163 184.057 165.498C184.81 167.82 185.691 170.495 184.727 172.891C183.716 175.405 181.097 176.428 178.616 176.812C177.279 176.998 175.928 177.072 174.579 177.032C173.015 177.073 171.452 176.915 169.928 176.562C168.622 176.201 167.363 175.51 166.744 174.248C166.289 173.321 166.144 172.07 166.654 171.134C166.787 170.894 166.968 170.685 167.185 170.518C167.401 170.352 167.649 170.232 167.914 170.165C168.178 170.098 168.453 170.087 168.722 170.131C168.991 170.176 169.249 170.275 169.478 170.423C170.037 170.737 170.493 171.208 170.792 171.778C171.09 172.348 171.218 172.993 171.16 173.635C170.738 176.934 166.992 178.259 164.25 178.874C161.229 179.552 157.822 180.049 155.664 182.538C155.206 183.071 154.836 183.675 154.57 184.327C154.53 184.476 154.55 184.635 154.626 184.769C154.701 184.903 154.826 185.001 154.974 185.043C155.121 185.081 155.278 185.059 155.41 184.983C155.543 184.908 155.641 184.783 155.685 184.637V184.637Z"
          fill="#3F3D56"
        />
      </g>
      <g>
        <path
          id="Vector_10"
          d="M109.023 179.899C111.365 179.899 113.264 177.987 113.264 175.629C113.264 173.27 111.365 171.358 109.023 171.358C106.681 171.358 104.782 173.27 104.782 175.629C104.782 177.987 106.681 179.899 109.023 179.899Z"
          fill="#146E81"
          ref={(path) => targets?.set("bubble1", path)}
        />
        <path
          id="Vector_11"
          d="M186.9 195.817C189.242 195.817 191.14 193.905 191.14 191.547C191.14 189.188 189.242 187.276 186.9 187.276C184.557 187.276 182.659 189.188 182.659 191.547C182.659 193.905 184.557 195.817 186.9 195.817Z"
          fill="#146E81"
          ref={(path) => targets?.set("bubble2", path)}
        />
        <path
          id="Vector_12"
          d="M165.696 217.171C167.399 217.171 168.78 215.78 168.78 214.065C168.78 212.349 167.399 210.959 165.696 210.959C163.992 210.959 162.611 212.349 162.611 214.065C162.611 215.78 163.992 217.171 165.696 217.171Z"
          fill="#146E81"
          ref={(path) => targets?.set("bubble3", path)}
        />
      </g>
      <path
        id="Vector_13"
        d="M55.7509 242.109C68.3452 244.938 82.2444 242.848 82.2444 242.848C82.2444 242.848 75.7241 236.17 63.1298 233.341C50.5355 230.511 36.6363 232.602 36.6363 232.602C36.6363 232.602 43.1566 239.28 55.7509 242.109Z"
        fill="#146E81"
      />
      <path
        id="Vector_14"
        d="M56.9932 246.88C70.7049 247.279 82.3399 242.829 82.3399 242.829C74.6516 239.477 66.4054 237.613 58.0323 237.334C44.3206 236.936 32.6855 241.386 32.6855 241.386C40.3738 244.737 48.62 246.601 56.9932 246.88V246.88Z"
        fill="#2EC0BF"
      />
      <path
        id="Vector_15"
        d="M57.9671 237.35C67.7627 242.323 82.1887 242.945 82.1887 242.945C82.1887 242.945 80.7329 235.504 70.9373 230.53C61.1416 225.557 46.7157 224.935 46.7157 224.935C46.7157 224.935 48.1715 232.377 57.9671 237.35Z"
        fill="#2EC0BF"
      />
      <path
        id="Vector_16"
        d="M293 24.4592V0H24.2882H0V24.4592V116.472V140.931V242.262H24.2882V140.931H293V116.472H24.2882V24.4592H293Z"
        fill="#146E81"
      />
      <path
        id="Vector_17"
        d="M293 1.94121H268.712V241.874H293V1.94121Z"
        fill="#146E81"
      />
      <path
        id="Vector_18"
        d="M46.3144 178.985C44.0755 182.484 41.5839 182.596 39.4748 181.227C39.4258 181.195 39.3775 181.163 39.3298 181.13C39.2337 181.064 39.1405 180.996 39.05 180.926C37.2514 179.526 36.5941 177.282 38.6763 174.028C40.8312 170.661 47.0885 168.707 47.5549 168.565H47.5556L47.5823 168.557C47.5823 168.557 48.5532 175.486 46.3144 178.985Z"
        fill="#146E81"
      />
      <path
        id="Vector_19"
        d="M201.682 89.2329C199.443 92.7319 196.951 92.8439 194.842 91.4752C194.793 91.4434 194.745 91.4111 194.697 91.3783C194.601 91.3123 194.508 91.2441 194.417 91.1737C192.619 89.7745 191.961 87.5305 194.043 84.2764C196.198 80.9088 202.456 78.9549 202.922 78.8129H202.923L202.949 78.8048C202.949 78.8048 203.92 85.7342 201.682 89.2329Z"
        fill="#146E81"
      />
      <path
        id="Vector_20"
        d="M113.024 63.2652C115.824 66.3273 115.305 68.7839 113.459 70.4955C113.416 70.5353 113.373 70.5743 113.33 70.6125C113.242 70.6896 113.153 70.7633 113.063 70.8338C111.266 72.2351 108.944 72.3114 106.34 69.4638C103.645 66.5168 103.34 59.9265 103.321 59.4362L103.321 59.4356L103.32 59.4075C103.32 59.4075 110.224 60.2034 113.024 63.2652Z"
        fill="#146E81"
      />
      <path
        id="Vector_21"
        d="M204.782 161.798L198.14 165.173C197.505 165.524 196.757 165.607 196.061 165.404C195.366 165.202 194.778 164.729 194.427 164.091L189.856 154.965C189.747 154.747 189.685 154.508 189.673 154.263L189.585 152.53C189.575 152.142 189.676 151.759 189.876 151.428C190.077 151.096 190.368 150.83 190.715 150.661L197.651 147.137C197.983 146.962 198.356 146.884 198.729 146.911C199.102 146.939 199.46 147.071 199.763 147.292L200.937 148.19C201.148 148.351 201.321 148.559 201.441 148.797L206.125 158.148C206.426 158.812 206.454 159.569 206.203 160.253C205.951 160.937 205.44 161.493 204.782 161.798V161.798Z"
        fill="#146E81"
      />
      <path
        id="Vector_22"
        d="M196.112 148.867L192.885 150.506L193.112 150.959L196.339 149.32L196.112 148.867Z"
        fill="#3F3D56"
      />
      <path
        id="Vector_23"
        d="M199.491 150.516L192.333 154.111L193.365 156.195L200.524 152.6L199.491 150.516Z"
        fill="#2EC0BF"
      />
      <path
        id="Vector_24"
        d="M201.033 153.233L193.875 156.828L194.908 158.913L202.066 155.318L201.033 153.233Z"
        fill="#2EC0BF"
      />
      <path
        id="Vector_25"
        d="M202.575 156.339L195.417 159.934L196.45 162.019L203.608 158.424L202.575 156.339Z"
        fill="#2EC0BF"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="293" height="263" fill="white" />
      </clipPath>
    </defs>
  </svg>
));

function CupOfTea() {
  const svgRef = React.useRef(null);
  const onScreen = useOnScreen(svgRef);
  const [playState, setPlayState] = React.useState(PlayState.stop);

  React.useEffect(() => {
    if (onScreen) setPlayState(PlayState.play);
  }, [onScreen]);

  return (
    <div ref={svgRef}>
      <Timeline playState={playState} target={<CupOfTeaIllustration />}>
        <Tween
          from={{ y: 180 }}
          to={{ y: 0 }}
          duration={2}
          target="water"
          position={0}
        />
        <Tween from={{ y: 10 }} duration={2} target="tea_bag" position={1} />
        <Tween
          from={{ opacity: 0, y: 50 }}
          to={{ opacity: 1, y: 0 }}
          target="bubble1"
          position={2}
        />
        <Tween
          from={{ opacity: 0, y: 40 }}
          to={{ opacity: 1, y: 0 }}
          target="bubble2"
          position={3}
        />
        <Tween
          from={{ opacity: 0, y: 10 }}
          to={{ opacity: 1, y: 0 }}
          target="bubble3"
          position={4}
        />
        <Tween
          from={{ rotation: 0 }}
          to={{ rotation: 3 }}
          duration={2}
          target="tea_bag"
          position={2}
          repeat={-1}
          yoyo
        />
      </Timeline>
    </div>
  );
}

export default CupOfTea;
